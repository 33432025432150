const data = {
    '01-07': 'Різдво',
    '01-19': 'Водохреща',
    '01-20': 'Івана (собор Івана Хрестителя)',
    '02-15': 'Стрітення',
    '04-07': 'Благовіщення',
    '08-19': 'Преображення',
    '08-28': 'Успіння Богородиці',
    '09-11': 'Усікновення глави Івана Предтечі',
    '09-21': 'Різдво Богородиці',
    '09-27': 'Воздвиження',
    '10-14': 'Покрова',
    '12-04': 'Введення в храм',

    '01-14': 'Василія',
    '02-06': 'Оксани',
    '07-07': 'Івана',
    '07-12': 'Петра і Павла',
    '07-24': 'Ольги',
    '07-28': 'Володимира',
    '09-08': 'Наталії',
    '09-30': 'Віри, Надії, Любові',
    '11-08': 'Дмитра',
    '11-21': 'Михайла',
    '12-01': 'Романа',
    '12-07': 'Катерини',
    '12-13': 'Андрія',
    '12-19': 'Миколая',
    '12-22': 'Анни'
};

export default data;
